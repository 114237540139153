import * as React from 'react';
import Layout from '../../components/Layout';

const DynamicBranding = props=>{
    return (
        <Layout>
            This is DynamicBranding
        </Layout>
    );
}

export default DynamicBranding;
